import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

const Spoiler = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="spoiler mb-4">
        <button 
          onClick={() => setIsOpen(!isOpen)} 
          className="flex items-center font-medium text-gray-900 hover:underline focus:outline-none"
        >
          {title}
          <span className="ml-2">
            {isOpen ? 
              <ChevronUpIcon className="w-5 h-5" /> : 
              <ChevronDownIcon className="w-5 h-5" />}
          </span>
        </button>
        <div 
          className={`spoiler-content mt-2 text-gray-600 pre-line text-left overflow-scroll transition-all duration-500 ease-in-out ${isOpen ? 'max-h-[900px]' : 'h-0'}`}
        >
          {children}
        </div>
      </div>
    );
  };
  
export default Spoiler;
