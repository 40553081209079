import React, { useState, useEffect, useRef } from 'react';
import imagesData from './images.json';

const Carousel = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [scrollY, setScrollY] = useState(0); // New state to track the vertical scroll position
    const nextImageIndex = useRef(1); // Using useRef to track the next image

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const parallaxShift = Math.round(scrollY * 0.4); // Adjust this value for the intensity of the parallax effect

    useEffect(() => {
        const interval = setInterval(() => {
            setIsTransitioning(true);

            // Increment nextImageIndex
            nextImageIndex.current = (nextImageIndex.current + 1) % imagesData.length;

            setTimeout(() => {
                setIsTransitioning(false);
                setCurrentImageIndex(nextImageIndex.current);
            }, 500); // 1s transition duration
        }, 5000); // Initiate transition every 5 seconds

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="hidden md:block relative h-[calc(85vh-60px)] w-full"> 
            {/* Current Image */}
            <img 
                src={imagesData[currentImageIndex].src}
                alt={imagesData[currentImageIndex].title}
                style={{ transform: `translateY(${parallaxShift}px)` }} // Parallax effect
                className="absolute -top-20 left-0 h-[110%] w-full object-cover -z-20"
            />
            {/* Next Image */}
            <img 
                src={imagesData[nextImageIndex.current].src}
                alt={imagesData[nextImageIndex.current].title}
                style={{ transform: `translateY(${parallaxShift}px)` }} // Parallax effect
                className={`absolute -top-20 left-0 h-[110%] w-full object-cover -z-10 transition-opacity duration-1000 ${isTransitioning ? 'opacity-100' : 'opacity-0'}`}
            />
            <div className="absolute left-0 w-full bg-gray-600 flex justify-center px-2 py-1 z-20">
                <div className="flex items-center">
                    <p 
                        className={`text-white uppercase text-sm ml-1 flex-shrink text-wrap transition-opacity duration-300 ${isTransitioning ? 'opacity-100' : 'opacity-100'}`}
                    >
                        Реализованные объекты: {imagesData[currentImageIndex].title}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Carousel;

/*
<div className="absolute top-8 right-2 w-full flex justify-center text-right px-2 py-1 z-20">
                <div className="flex items-center text-right">
                    <p 
                        className={`text-gray-300 text-xs text-right ml-1 flex-shrink text-wrap transition-opacity duration-300 ${isTransitioning ? 'opacity-0' : 'opacity-50'}`}
                    >
                        Реализованные объекты: {imagesData[currentImageIndex].description}
                    </p>
                </div>
            </div>

*/