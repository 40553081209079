import React from 'react';
import { Link } from 'react-router-dom';


const ProductCard = ({ product }) => {
    return (
      <Link 
        to={`/product/${product.id}`} 
        className="border p-4 rounded-md hover:shadow-xl hover:bg-gray-100 transition-all duration-300"
      >
        <div className="md:flex items-center gap-4">
          {/* Product Image */}
          <div className="w-full md:w-1/3">
            <img src={process.env.PUBLIC_URL + product.image} alt={product.title} className="w-full h-auto rounded-md max-h-[300px] object-contain" />
          </div>
  
          {/* Product Info */}
          <div className="w-full md:w-2/3">
            <h3 className="text-xl font-bold mb-2">{product.title}</h3>
            <p className="mb-4">{product.description}</p>
          </div>
        </div>
      </Link>
    );
  };
  
export default ProductCard;
