import React from "react";
import FeatureCard from "./FeatureCard";
import { Link } from 'react-router-dom';

const ApplicationsSection = ({ features }) => {
    return (
      <section className="py-12 px-4 bg-white">
        <div className="container mx-auto my-4">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">Применение</h2>
        <div className="grid grid-cols-1 text-gray-600 md:grid-cols-2 gap-8">
          {features.map((feature, idx) => (
            <FeatureCard key={idx} feature={feature} classProps="w-36 h-42 mr-8"/>
          ))}
        </div>
        </div>
      </section>
    );
  };

  export default ApplicationsSection;