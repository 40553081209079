import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from "./red_logo.svg";
import HomeAnchorLink from './NavLink';
import './App.css';

const NavLink = ({ to, children }) => (
  <Link 
    to={to} 
    className="transition-colors duration-300 ease-in-out text-gray-900 hover:text-gray-400 active:text-gray-600">
      {children}
  </Link>
);

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white p-4 sticky top-0 z-30 drop-shadow-md">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          
          {/* Logo */}
          <HomeAnchorLink to="#gallery">
            <div className="flex justify-between items-center">
            <img src={logo} alt="Logo" className="h-12 w-auto" />
            <h1 className="text-gray-900 font-bold text-lg pl-2">МАСТ </h1>
            <h1 className="text-gray-900 font-bold text-lg invisible lg:visible pl-2 pr-2">ГИДРОИЗОЛЯЦИЯ</h1>
            </div>
          </HomeAnchorLink>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-4">
            {/* <HomeAnchorLink to="#gallery" className="text-gray-900">Главная</HomeAnchorLink> */}
            <HomeAnchorLink to="#about" className="no-wrap text-gray-900">О компании</HomeAnchorLink>
            <HomeAnchorLink to="#products" className="text-gray-900">Каталог</HomeAnchorLink>
            <HomeAnchorLink to="#features" className="text-gray-900">Преимущества</HomeAnchorLink>
            <HomeAnchorLink to="#applications" className="text-gray-900">Применение</HomeAnchorLink>
            <HomeAnchorLink to="#testimonials" className="text-gray-900">Отзывы</HomeAnchorLink>
            <HomeAnchorLink to="#contact" className="text-gray-900">Контакты</HomeAnchorLink>
          </div>

          {/* Mobile Menu Button */}
          <button 
            className="md:hidden flex items-center sticky top-0" 
            onClick={() => setIsOpen(!isOpen)}>
            <img src="/menu.svg" alt="Menu" className="h-6 w-auto text-gray-900" />
          </button>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="mt-1 text-right font-medium text-gray-900 text-2xl md:hidden flex flex-col sticky top-0">
            {/* <HomeAnchorLink to="#gallery" className="text-gray-900">Главная</HomeAnchorLink> */}
            <HomeAnchorLink to="#about" className="block py-2 px-4 no-underline leading-none font-normal">О компании</HomeAnchorLink>
            <HomeAnchorLink to="#products" className="block py-2 px-4 no-underline leading-none font-normal">Каталог</HomeAnchorLink>
            <HomeAnchorLink to="#features" className="block py-2 px-4 no-underline leading-none font-normal">Преимущества</HomeAnchorLink>
            <HomeAnchorLink to="#applications" className="block py-2 px-4  no-underline leading-none font-normal">Применение</HomeAnchorLink>
            <HomeAnchorLink to="#testimonials" className="block py-2 px-4  no-underline leading-none font-normal">Отзывы</HomeAnchorLink>
            <HomeAnchorLink to="#contact" className="block py-2 px-4  no-underline leading-none font-normal">Контакты</HomeAnchorLink>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
