import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Home';
import Navbar from './Navbar';
import Contact from './Contact';
import ProductDetail from './ProductDetail';
import "./logo.svg"
import ProductsSection from './ProductSection';
import InstructionsWell from './InstructionsWell';
import FullPageTestimonials from './Testimonials';

function App() {
  return (
    <Router>
      <Navbar /> {/* Render Navbar outside the Routes so it's always visible */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/all-testimonials" element={<FullPageTestimonials />} />
        {/* Remove hash routes if they are meant for in-page navigation */}
        {/* If InstructionsWell is a page, uncomment and use the correct prop */}
        {/* <Route path="/instructionsWell" element={<InstructionsWell />} /> */}
        {/* Ensure your dynamic routes are correctly defined */}
        {/* Example dynamic route: */}
        {/* <Route path="/product/:productId" element={<ProductDetail />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
