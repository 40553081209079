import React from 'react';
import Card from './TextCard';
import cardsData from './text-cards.json';

const GridSection = () => {
    return (
        <section className="py-12 px-4 bg-gray-600">
            <div className="container mx-auto my-4">
                <h2 className="text-3xl font-semibold text-center mb-8 text-white">Оплата и доставка</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 pre-line gap-8">
                    {cardsData.map((card, idx) => (
                        <Card key={idx} title={card.title} text={card.text} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default GridSection;
