import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonialsData from "./testimonials.json";
import { Link } from "react-router-dom"; // Add this import at the top

const TestimonialCard = ({ testimonial }) => (
  <div className="m-2 p-6 max-h-[500px] overflow-y-auto rounded-md shadow-lg bg-white">
      <blockquote className="text-gray-800 pre-line">"{testimonial.quote}"</blockquote>
      <p className="mt-4 italic text-gray-600">{testimonial.name}</p>
      <p className="pt-1 text-gray-800 font-black">{testimonial.company}</p>
  </div>
);

const TestimonialsSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="py-10 px-4 bg-white">
      <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">
        Отзывы
      </h2>
      <Slider {...settings}>
        {testimonialsData.map((testimonial, idx) => (
          <TestimonialCard key={idx} testimonial={testimonial} />
        ))}
      </Slider>
      <div className="text-center m-4 mt-12">
        <Link to="/all-testimonials" className="text-blue-600 font-semibold text-lg underline hover:underline">
          Смотреть все отзывы
        </Link>
      </div>
    </section>
  );
};

export default TestimonialsSection;
