import React from "react";
import ContactForm from "./ContactForm";


const Contact = () => {
    return (
        <div className="container mx-auto px-16">
            <section className="my-8">
                <h2 className="text-3xl text-center text-gray-800 font-semibold mb-8">Контакты</h2>
                <div className="text-center text-gray-600 justify-center md:grid-cols-2">
                    <div className="md:text-center">
                        <h3 className="text-2xl text-gray-800 font-semibold mb-4">ООО МаСт</h3>
                        <h4 className="text-xl font-semibold my-4">Адрес</h4>
                        <p className="mb-2 text-gray-800">Московская Область, Люберцы, пос. Красково,</p>
                        <p className="mb-2 text-gray-800">ул.Карла Маркса 117 корпус 3</p>
                        <h4 className="text-xl font-semibold my-4">Телефоны</h4>
                        <p className="mb-2 text-gray-800">+7-916-671-90-14, +7-926-429-32-80, +7-495-783-91-57</p>
                        <h4 className="text-xl font-semibold my-4">Электронный адрес</h4>
                        <p className="mb-2 text-gray-800">ooo.must@gmail.com</p>
                        
                    </div>
                </div>
               
            </section>
        </div>

)};

export default Contact;