import React from 'react';
import productsData from './products.json';
import ProductCard from './ProductCard';

const ProductsSection = () => {
    // Assuming you're importing product data from a JSON or similar
    const products = productsData; // Replace with actual data fetching or importing logic
  
    return (
      <section className="mt-2 mb-8 bg-white">
        <div className="container mx-auto">
          
          {/* Heading */}
          <h2 className="text-3xl font-semibold text-gray-900 text-center pt-4 mb-8">Герметики МАСТ</h2>
  
          {/* Product cards */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-screen-xl mx-8">
          {products.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
  
        </div>
      </section>
    );
  };

export default ProductsSection;
