import React from "react";
import FeatureCard from "./FeatureCard";

const FeaturesSection = ({ features }) => {
    return (
      <section className="py-12 px-4 text-white bg-gray-600">
        <div className="container mx-auto my-4">
        <h2 className="text-3xl font-semibold text-white text-center mb-8">Преимущества МАСТ</h2>
        <div className="grid grid-cols-1 text-white md:grid-cols-2 gap-8">
          {features.map((feature, idx) => (
            <FeatureCard key={idx} feature={feature} classProps="w-16 h-16 mr-8"/>
          ))}
        </div>
        </div>
      </section>
    );
  };

  export default FeaturesSection;