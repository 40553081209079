import React from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';

const HomeAnchorLink = ({ to, children, ...props }) => {
    const location = useLocation();
    const navigate = useNavigate();
  
    const handleClick = (e) => {
      e.preventDefault();
      if (location.pathname !== '/') {
        navigate('/'); // Navigate to Home page
        // After navigation, we delay the scroll to ensure the new page is rendered
        setTimeout(() => {
          const element = document.getElementById(to.replace('#', ''));
          if (element) element.scrollIntoView();
        }, 100);
      }
      else {
        const element = document.getElementById(to.replace('#', ''));
        if (element) element.scrollIntoView();
      }
    };
  
    return (
      <a
        href={to}
        {...props}
        onClick={handleClick}
        className="transition-colors no-wrap duration-300 ease-in-out text-gray-900 hover:text-gray-400 active:text-gray-700">
        {children}
      </a>
    );
  };

  export default HomeAnchorLink