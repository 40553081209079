import React from 'react';
import productsData from './products.json';
import CertificateCard from './CertificateCard';

const ProductsSection = () => {
    // Assuming you're importing product data from a JSON or similar
    // const products = productsData; // Replace with actual data fetching or importing logic
  
    return (
      <section className="mt-2 mb-0 bg-gray-100">
        <div className="container mx-auto">
          
          {/* Heading */}
          <h2 className="text-3xl font-semibold text-gray-900 text-center pt-8 mb-8">Сертификаты на материалы для гидроизоляции МаСт</h2>
  
          {/* Product cards */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 pb-8 max-w-screen-xl mx-auto">
            <CertificateCard name={"Санитарно-эпидемиологическое заключение на сухие смеси для гидроизоляции МаСт"} image={"/sert_g1.jpg"} />
            <CertificateCard name={"Санитарно-эпидемиологическое заключение на сухие смеси для гидроизоляции МаСт"} image={"/sert_g2.jpg"} />
            <CertificateCard name={"Сертификат Соответствия - Герметик Расширяющийся"} image={"/sert_r.jpg"} />
            <CertificateCard name={"Сертификат Соответствия - Герметик Пенетрирующий"} image={"/sert_p.jpg"} />
            <CertificateCard name={"Санитарно-эпидемиологическое заключение на документацию ТУ 5745-002-39849510-96"} image={"/thumb4.jpg"} />
        </div>
  
        </div>
      </section>
    );
  };

export default ProductsSection;
