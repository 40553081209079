// FullPageTestimonials.js

import React from 'react';
import testimonialsData from './testimonials.json';
import { useEffect } from 'react';
// import TestimonialCard from './TestimonialCard'; // Assuming TestimonialCard is in a separate file

const TestimonialCard = ({ testimonial }) => (
  <div className="m-2 p-6 max-h-[500px] overflow-y-auto rounded-md shadow-lg bg-white w-full">
    <blockquote className="text-gray-800 pre-line">"{testimonial.quote}"</blockquote>
    <p className="mt-4 italic text-gray-600">{testimonial.name}</p>
    <p className="pt-1 text-gray-800 font-black">{testimonial.company}</p>
  </div>
);

const TestimonialCardLong = ({ testimonial }) => (
  <div className="m-4 p-12 overflow-y-auto rounded-md shadow-lg bg-white w-full max-w-3xl mx-auto">
    <p className="m-4 pt-1 text-center text-blue-800 text-2xl font-black">{testimonial.company}</p>
    <blockquote className="text-gray-800 pre-line">"{testimonial.quote_long}"</blockquote>
    <p className="mt-4 text-right italic text-blue-600">{testimonial.name}</p>

  </div>
);
  
const FullPageTestimonials = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="py-10 px-4 bg-white">
      <h2 className="text-3xl font-semibold text-center text-gray-800 mb-4">Отзывы</h2>
      <div className="flex flex-col items-center">
        {testimonialsData.map((testimonial, idx) => (
          <TestimonialCardLong key={idx} testimonial={testimonial} />
        ))}
      </div>
    </div>
  );
};

export default FullPageTestimonials;
