function ContactForm() {
    return (
      <form action="https://formspree.io/f/xyyqedbe" method="POST" className="w-full mx-auto">
        <h2 className="text-2xl font-semibold text-gray-900 text-center mb-8">Есть вопросы? Свяжитесь с нами:</h2>
        <div className="mb-4">
          <input 
            type="text" 
            name="name" 
            id="name"
            placeholder="Имя"
            required 
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-gray-500 focus:ring focus:ring-gray-200 transition-shadow"
          />
        </div>
        
        <div className="mb-4">
          <input 
            type="email" 
            name="_replyto"
            id="email"
            placeholder="Email"
            required 
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-gray-500 focus:ring focus:ring-gray-200 transition-shadow"
          />
        </div>
        
        <div className="mb-4">
          <textarea 
            name="message" 
            id="message"
            rows="4"
            placeholder="Сообщение"
            required 
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-gray-500 focus:ring focus:ring-gray-200 transition-shadow"
          ></textarea>
        </div>
        
        <button 
          type="submit" 
          className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-600 active:bg-gray-1000 focus:outline-none focus:ring focus:ring-gray-200 transition-shadow"
        >
          Отправить
        </button>
      </form>
    );
  }
  
  export default ContactForm;
  