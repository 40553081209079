const TextCard = ({ title, text }) => {
    return (
        <div className="p-4 border rounded leading-relaxed shadow-lg bg-white">
            <h3 className="text-xl font-bold mb-2">{title}</h3>
            <p>{text}</p>
        </div>
    );
};

export default TextCard;
