import React from 'react';
import Carousel from './Carousel';
import Contact from './Contact';
import Navbar from './Navbar';
import ProductsSection from './ProductSection';
import FeaturesSection from './FeaturesSection';
import featuresData from './features.json';
import applicationsData from './applications.json';
import ApplicationsSection from './ApplicationsSection';
import GridSection from './GridSection';
import TestimonialsSection from './TestimonialsSection';
import About from './About';
import InstructionsWell from './InstructionsWell';
import Certificates from './Certificates';

const Home = () => {
  return (
    <div className="mx-auto">
      <div id="gallery">
      <Carousel />
      </div>
      <div id="products">
      <ProductsSection />
      </div>
      <div id="features">
      <FeaturesSection features={featuresData} />
      </div>
      {/* <div>
        <InstructionsWell />
      </div> */}
      <div id="applications">
      <ApplicationsSection features={applicationsData}/>
      </div>
      <div id="about">
      <About />
      </div>
      <div id="testimonials">
      <TestimonialsSection />
      </div>
      <GridSection />
      <div id="contact">
      <Contact />
      </div>
    </div>
  );
}

export default Home;
