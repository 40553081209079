import React from 'react';
import { Link } from 'react-router-dom';

const FeatureCard = ({ feature, classProps }) => {
  return (
    <div className="flex items-center">
      {/* If the image should not be part of the link, keep it outside. If it should, move it inside the Link component */}
      <img src={feature.image} alt={feature.title} className={classProps} />
      <div>
        {/* Convert anchor tags to Link components */}
        <Link to={`/product/${feature.link}`}>
          <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
        </Link>
        <Link to={`/product/${feature.link}`}>
          <p className="text-sm">{feature.description}</p>
        </Link>
      </div>
    </div>
  );
};

export default FeatureCard;