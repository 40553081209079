import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import productsData from './product-data.json';
import Spoiler from './Spoiler';
import { PlusCircleIcon } from '@heroicons/react/solid';
import Certificates from './Certificates';
import Contact from './Contact';
import GridSection from './GridSection';

const imageUrl = "/plus.svg";

const ProductDetail = () => {
    const { id } = useParams();
    const product = productsData.find(p => p.id.toString() === id);
  
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
  
    if (!product) {
      return <div>Product not found</div>;
    }
  
    return (
      <div>
        <div className="hidden absolute top-20 left-0 w-full h-8 bg-gray-600 md:flex items-center z-order justify-center">
          <h2 className="text-white text-sm">Сухая смесь для гидроизоляции {product.title} - {product.subtitle}</h2>
        </div>
  
        <div className="container mx-auto p-6 max-w-6xl">
          {/* Title */}
          <h1 className="text-4xl text-center font-bold text-gray-900 mt-16 mb-4">{product.subtitle}</h1>
          <h3 className="text-xl text-center font-bold text-gray-600 mb-16">{product.title}</h3>
  
          {/* First Section */}
          <div className="md:flex items-center gap-6 mb-6 m-4">
            {/* Image */}
            <div className="w-full md:w-1/3">
              <img src={process.env.PUBLIC_URL + product.image} alt={product.title} className="w-full object-contain h-auto max-h-[300px]" />
            </div>
  
            {/* Main Features Grid */}
            <div className="w-full md:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-8">
              {product.mainFeatures.map((feature, idx) => (
                <ul className='list-none text-left text-gray-600'><li key={idx}><PlusCircleIcon className="h-5 w-5 mr-2 text-gray-500 inline-block" />{feature}</li></ul>
              ))}
            </div>
          </div>
        </div>
  
        {/* Назначение */}
        <section className="bg-gray-100">
          <div className="container mx-auto p-6 max-w-2xl text-center">
            <h2 className="text-3xl font-semibold text-gray-900 mb-8 mt-4">Назначение</h2>
            <p className='mb-4 text-left text-gray-600 pre-line'>{product.applications}</p>
            <div className="w-full flex-col gap-8 text-left mb-12">
              {product.applicationsList.map((application, idx) => (
                <ul className='list-disc text-gray-600 mb-4 pl-20 pre-line'><li key={idx}>{application}</li></ul>
              ))}
            </div>
          </div>
        </section>

        {/* Принцип действия */}
        <section className="bg-white">
          <div className="container mx-auto p-6 max-w-2xl text-center">
            <h2 className="text-3xl font-semibold text-gray-900 mb-8 mt-8">Принцип действия</h2>
            <p className='pre-line text-left mb-12'>{product['principle']}</p>
          </div>
        </section>


        {/* Инструкция по применению */}
        <section className="bg-gray-100">
          <div className="container mx-auto p-6 mt-8 mb-12 max-w-2xl text-center">
            <h2 className="text-3xl font-semibold text-gray-900 mb-8">Инструкция по применению</h2>
            { product.instructions.envConditions && <div className='mb-4 text-left text-gray-600 pre-line'>{product.instructions.envConditions}</div>}
            { product.instructions.howMuch && <Spoiler className='mb-4 font-semibold text-gray-900 pre-line' title="Расход">{product.instructions.howMuch}</Spoiler>}
            { product.instructions.howToUse && <Spoiler className='mb-4 font-semibold text-gray-900 pre-line' title="Применение">{product.instructions.howToUse}</Spoiler>}
            { product.instructions.preparation && <Spoiler className='mb-4 font-semibold text-gray-900 pre-line' title="Подготовка поверхности">{product.instructions.preparation}</Spoiler>}
            { product.instructions.surfaceCare && <Spoiler className='mb-4 font-semibold text-gray-900 pre-line' title="Уход за обработанной поверхностью">{product.instructions.surfaceCare}</Spoiler>}
            { product.instructions.precautions && <Spoiler className='mb-4 font-semibold text-gray-900 pre-line' title="Меры предосторожности">{product.instructions.precautions}</Spoiler>}
          </div>
        </section>

        {/* Технология приготовления */}
        <section className="bg-white">
          <div className="container mx-auto p-6 mt-8 mb-12 max-w-2xl text-center">
            <h2 className="text-3xl font-semibold text-gray-900 mb-8">Технология приготовления</h2>
            { product.instructions.prepGeneric && <div className='mb-4 text-left text-gray-600 pre-line'>{product.instructions.prepGeneric}</div>}
            { product.instructions.prepWallChase && <Spoiler className='mb-4 font-semibold text-gray-900 pre-line' title="Для герметизации штробы">{product.instructions.prepWallChase}</Spoiler>}
            { product.instructions.prepManual && <Spoiler className='mb-4 font-semibold text-gray-900 pre-line' title="Для герметизации поверхности вручную">{product.instructions.prepManual}</Spoiler>}
            { product.instructions.prepMechanical && <Spoiler className='mb-4 font-semibold text-gray-900 pre-line' title="Для герметизации поверхности механизированным способом">{product.instructions.prepMechanical}</Spoiler>}
            </div>
        </section>

        {/* Характеристики камня из затвердевшей мастики */}
        <section className="bg-gray-100">
          <div className="container mx-auto p-6 max-w-2xl text-center">
            <h2 className="text-3xl font-semibold text-gray-900 mb-4 mt-4">Технические характеристики материала</h2>
            <div class="flex flex-col">
              <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-10 sm:px-6 lg:px-8">
                  <div class="overflow-hidden">
                    <table class="min-w-full text-center text-sm font-light">
                      <tbody>
                        <tr class="border-b bg-neutral-200">
                          <td class="whitespace-nowrap px-6 py-2 text-left font-medium">Водонепроницаемость</td>
                          <td class="whitespace-nowrap px-6 py-2"></td>
                          <td class="whitespace-nowrap px-6 py-2">{product.techChart.waterproofW}</td>
                          
                        </tr>
                        { product.techChart.timeStartFrom && <tr>
                          <td class="whitespace-nowrap px-6 py-2 text-left font-medium ">Сроки схватывания</td>
                          <td class="whitespace-nowrap px-6 py-2">начало, не ранее</td>
                          <td class="whitespace-nowrap px-6 py-2">{product.techChart.timeStartFrom}</td>
                          
                        </tr>}
                        { product.techChart.timeStartTo && <tr>
                          <td class="whitespace-nowrap px-6 py-2 text-left font-medium "></td>
                          <td class="whitespace-nowrap px-6 py-2">не позднее</td>
                          <td class="whitespace-nowrap px-6 py-2">{product.techChart.timeStartTo}</td>
                          
                        </tr>}
                        { product.techChart.timeEndFrom && <tr>
                          <td class="whitespace-nowrap px-6 py-2 text-left font-medium "></td>
                          <td class="whitespace-nowrap px-6 py-2">конец, не ранее</td>
                          <td class="whitespace-nowrap px-6 py-2">{product.techChart.timeEndFrom}</td>
                          
                        </tr>}
                        { product.techChart.timeEndTo && <tr>
                          <td class="whitespace-nowrap px-6 py-2 text-left font-medium "></td>
                          <td class="whitespace-nowrap px-6 py-2">не позднее</td>
                          <td class="whitespace-nowrap px-6 py-2">{product.techChart.timeEndTo}</td>
                          
                        </tr>}
                        <tr>
                          <td rowspan="2" class="whitespace-nowrap px-6 py-2 text-left font-medium ">Прочность на сжатие</td>
                          <td class="whitespace-nowrap px-6 py-2">через 1 сутки</td>
                          <td class="whitespace-nowrap px-6 py-2">{product.techChart.durability1Day}</td>
                          
                        </tr>
                        <tr>
                          <td class="whitespace-nowrap px-6 py-2">через 28 суток</td>
                          <td class="whitespace-nowrap px-6 py-2">{product.techChart.durability28Days}</td>
                        </tr>
                        <tr class="border-b bg-neutral-200">
                          <td colspan="2" class="whitespace-nowrap px-6 py-2 text-left font-medium">Расширение</td>
                          <td class="whitespace-nowrap px-6 py-2">{product.techChart.expansion}</td>
                        </tr>
                        <tr>
                          <td colspan="2" class="whitespace-nowrap px-6 py-2 text-left font-medium">Адгезия</td>
                          <td class="whitespace-nowrap px-6 py-2">{product.techChart.adhesion}</td>
                        </tr>
                        <tr class="border-b bg-neutral-200">
                          <td colspan="2" class="whitespace-nowrap px-6 py-2 text-left font-medium">Морозостойкость</td>
                          <td class="whitespace-nowrap px-6 py-2">{product.techChart.coldResistance}</td>
                        </tr>
                        <tr>
                          <td colspan="2" class="whitespace-nowrap px-6 py-2 text-left font-medium ">Применимость для резервуаров с питьевой водой</td>
                          <td class="whitespace-nowrap px-6 py-2">допускается</td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Характеристики */}
        <section className="bg-white">
          <div className="container mx-auto p-6 max-w-2xl text-center">
            <h2 className="text-3xl font-semibold text-gray-900 mb-8 mt-8">Прочие характеристики</h2>
            <p className='pre-line text-left mb-12'>{product.technicalCharacteristics}</p>
          </div>
        </section>

        <section>
        <Certificates />
        </section>
        <GridSection />
        <section>
        <Contact />
        </ section>


        {/* Техническая документация */}
        {/* <section className="bg-gray-100">
          <div className="container mx-auto p-6 max-w-2xl text-center">
            <h2 className="text-3xl font-semibold text-gray-900 mb-8 mt-8">Техническая документация</h2>
            <ul className="text-left mb-12">
              {product.certificates.map((certificate, idx) => (
                <li key={idx}>
                  <a href={process.env.PUBLIC_URL + certificate.file} download>{certificate.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </section> */}
      </div>
    );
  };
  
export default ProductDetail;
