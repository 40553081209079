import React from 'react';

const CertificateCard = ({ name, image }) => {
  const openImageInNewTab = (image) => {
    //window.open(image, '_blank');
  };

  return (
    <div className="border p-4 rounded-md hover:shadow-xl hover:bg-white bg-gray-50 transition-all duration-300">
      <div className="md:flex items-center gap-4">
        {/* Product Image */}
        <div className="w-full md:w-1/3" onClick={() => openImageInNewTab(process.env.PUBLIC_URL + image)}>
          <img src={process.env.PUBLIC_URL + image} alt={name} className="w-full h-auto max-h-[300px] object-contain cursor-pointer" />
        </div>

        {/* Product Info */}
        <div className="w-full md:w-2/3">
          <h3 className="text-md mb-2">{name}</h3>
          {/* <p className="mb-4">{product.description}</p> */}
        </div>
      </div>
    </div>
  );
};

export default CertificateCard;
